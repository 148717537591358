import React from 'react';

function PolitiqueConfidentialite() {
    return (
        <div style={{ color: 'black', padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
            <h1>Politique de Confidentialité de Starz Webradio</h1>

            <p>Bienvenue sur Starz Webradio ! La confidentialité de nos utilisateurs est une priorité. Cette politique de confidentialité explique comment nous collectons, utilisons, et protégeons les informations personnelles des visiteurs de notre site.</p>

            <h2>1. Données collectées</h2>
            <p>Lorsque vous utilisez notre site, nous pouvons collecter des informations personnelles pour améliorer votre expérience :</p>
            <ul>
                <li><strong>Données d'écoute</strong> : En utilisant notre lecteur de radio en ligne, nous collectons des informations sur les stations écoutées et la durée d’écoute.</li>
                <li><strong>Cookies</strong> : Nous utilisons des cookies pour personnaliser votre expérience, mesurer l’audience du site, et mémoriser vos préférences.</li>
            </ul>

            <h2>2. Utilisation des données</h2>
            <p>Nous utilisons les informations recueillies pour :</p>
            <ul>
                <li><strong>Améliorer l’expérience utilisateur</strong> en affichant vos stations préférées.</li>
                <li><strong>Mesurer l’audience et les performances</strong> du site pour mieux répondre aux besoins des utilisateurs.</li>
            </ul>

            <h2>3. Partage des données</h2>
            <p>Nous ne partageons pas vos informations personnelles avec des tiers, sauf dans les cas suivants :</p>
            <ul>
                <li><strong>Obligations légales</strong> : Si la loi nous y oblige, nous partagerons les informations nécessaires avec les autorités compétentes.</li>
                <li><strong>Services de suivi anonymisés</strong> : Nous pouvons utiliser des outils d’analyse anonymisés pour comprendre l’audience du site sans identifier individuellement nos visiteurs.</li>
            </ul>

            <h2>4. Gestion des cookies</h2>
            <p>Vous pouvez accepter ou refuser les cookies via notre bandeau de consentement. Si vous acceptez, nous utiliserons des cookies pour améliorer votre expérience de navigation. Vous pouvez également configurer votre navigateur pour gérer vos préférences en matière de cookies.</p>

            <h2>5. Vos droits</h2>
            <p>Conformément aux lois en vigueur, vous avez le droit de :</p>
            <ul>
                <li>Accéder à vos données personnelles.</li>
                <li>Demander leur rectification ou suppression.</li>
                <li>Limiter ou refuser le traitement de vos données.</li>
            </ul>
        </div>
    );
}

export default PolitiqueConfidentialite;