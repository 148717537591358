import React, { useState } from 'react';
import './CookieConsentBanner.css';

function CookieConsentBanner({ onConsent }) {
    const [isVisible, setIsVisible] = useState(true);

    const handleConsent = (consentGiven) => {
        setIsVisible(false);
        onConsent(consentGiven);
    };

    if (!isVisible) return null;

    return (
        <div className="cookie-banner">
            <div className="cookie-content">
                <h2>Vous prendrez bien quelques cookies ? 🍪</h2>
                <p>
                    <p>
                        Nos cookies ne sont pas gourmands, mais ils nous aident à améliorer votre expérience en nous permettant d'analyser le trafic et de mieux comprendre vos besoins.
                    </p>
                    <p>
                        Vous êtes d'accord ? Cliquez sur « Oui, j'accepte ! »<br />
                        Vous préférez sans ? Pas de souci, on respecte votre choix. 😊
                    </p>
                </p>
                <p>En savoir plus sur notre <a href="/politique-confidentialite" target="_blank">politique de confidentialité</a>.</p>
                <div className="cookie-buttons">
                    <button onClick={() => handleConsent(false)} className="btn btn-refuse">Non merci</button>
                    <button onClick={() => handleConsent(true)} className="btn btn-accept">Ok pour moi !</button>
                </div>
            </div>
        </div>
    );
}

export default CookieConsentBanner;