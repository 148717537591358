import React, { useState, useEffect } from 'react';
import './Hero.css';

function Hero() {
    const [heroImage, setHeroImage] = useState(null);

    // Données statiques pour les catégories
    const categories = [
        { id: 1, name: 'Permanentes', start_date: '01-05', end_date: '12-01' },
        { id: 2, name: 'Halloween', start_date: '10-10', end_date: '11-02' },
        { id: 3, name: 'Noel', start_date: '12-01', end_date: '12-26' },
        { id: 4, name: 'NouvelAn', start_date: '12-26', end_date: '01-05' },
    ];

    // Images associées aux catégories
    const heroImages = {
        Permanentes: [
            'permanentes001.png',
            'rnb.png',
            'reggae.png',
            'rap.png',
            'newhits.png',
            'love.png',
            'lounge.png',
            'latino.png',
            'kpop.png',
            'jazz.png',
            'italia.png',
            'funk.png',
            'dance.png',
            'permanentes002.png',
            'classical.png',
            'christmas.png',
            'permanentes003.png',
            'permanentes004.png',
            'permanentes005.png',
            'permanentes006.png',
            'permanentes007.png',
            'permanentes008.png',
            'permanentes009.png',
            'blues.png',
            'permanentes010.png',
            'permanentes011.png',
            '90s.png',
            '80s.png',
            '70s.png',
            '60s.png',
            '10s.png',
            '00s.png'
        ],
        Halloween: [
            'chuckysw.png',
            'chuckysw2.png',
            'chuckysw3.png',
            'creaturesw.png',
            'draculasw.png',
            'frankensteinsw.png',
            'freddysw.png',
            'freddysw2.png',
            'freddysw3.png',
            'ghostfacesw.png',
            'ghostfacesw2.png',
            'ghostfacesw3.png',
            'jacksw.png',
            'jasonsw.png',
            'jasonsw2.png',
            'jasonsw3.png',
            'leatherfacesw.png',
            'leatherfacesw2.png',
            'leatherfacesw3.png',
            'leprechaunsw.png',
            'loupgarousw.png',
            'momiesw.png',
            'myerssw.png',
            'myerssw2.png',
            'myerssw3.png',
            'operasw.png',
            'pinheadsw.png',
            'pinheadsw2.png',
            'pinheadsw3.png',
            'regansw.png',
            'regansw2.png',
            'regansw3.png',
            'robotsw.png',
            'robotsw2.png',
            'sawsw.png',
            'sawsw2.png',
            'sawsw3.png',
            'zombiesw.png',
            'zombiesw2.png',
            'zombiesw3.png',
            'halloween001.png',
            'halloween002.png',
            'halloween003.png',
            'halloween004.png',
            'halloween005.png',
            'halloween006.png',
            'halloween007.png'
        ],
        Noel: [
            'noel001.png',
            'noel002.png',
            'noel003.png',
            'noel004.png',
            'noel005.png',
            'noel006.png',
            'noel007.png',
            'noel008.png',
            'noel009.png',
            'noel010.png',
            'noel011.png',
            'noel012.png',
            'noel013.png',
            'noel014.png',
            'noel015.png',
            'noel016.png',
            'noel017.png',
            'noel018.png',
            'noel019.png',
            'noel020.png',
            'noel021.png',
            'noel022.png',
            'noel023.png',
            'noel024.png',
            'noel025.png',
            'noel026.png',
            'noel027.png',
            'noel028.png',
            'noel029.png',
            'noel030.png',
            'noel031.png',
            'noel032.png',
            'noel033.png',
            'noel034.png',
            'noel035.png',
            'noel036.png',
            'noel037.png',
            'noel038.png',
            'noel039.png',
            'noel040.png',
            'noel041.png',
            'noel042.png',
            'noel043.png',
            'noel044.png',
            'noel045.png',
            'noel046.png',
            'noel047.png',
            'noel048.png',
            'noel049.png',
            'noel050.png',
            'noel051.png'
        ],
        NouvelAn: [
            'nouvelan001.png',
            'nouvelan002.png',
            'nouvelan003.png',
            'nouvelan004.png',
            'nouvelan005.png',
            'nouvelan006.png',
            'nouvelan007.png',
            'nouvelan008.png',
            'nouvelan009.png',
            'nouvelan010.png',
            'nouvelan011.png',
            'nouvelan012.png',
            'nouvelan013.png',
            'nouvelan014.png',
            'nouvelan015.png',
            'nouvelan016.png',
            'nouvelan017.png',
            'nouvelan018.png',
            'nouvelan019.png',
            'nouvelan020.png'
        ],
    };

    useEffect(() => {
        const today = new Date();
        const todayStr = getFormattedDate(today);

        // Trouver la catégorie actuelle
        const currentCategory = categories.find((category) => {
            const startDate = parseDate(category.start_date);
            const endDate = parseDate(category.end_date);

            if (endDate < startDate) {
                // Gérer les périodes chevauchantes comme Noel (par exemple, 12-26 à 01-05)
                return todayStr >= startDate || todayStr <= endDate;
            }
            return todayStr >= startDate && todayStr <= endDate;
        }) || { name: 'Permanentes' }; // Par défaut, afficher "Permanentes"

        // Sélectionner une image aléatoire pour la catégorie actuelle
        const images = heroImages[currentCategory.name];
        if (images && images.length > 0) {
            const randomImage = images[Math.floor(Math.random() * images.length)];
            setHeroImage({ image_url: randomImage, categoryName: currentCategory.name });
        }
    }, []);

    // Formater une date (sans l'année)
    const getFormattedDate = (date) => {
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${month}-${day}`;
    };

    // Analyser une date (sans l'année)
    const parseDate = (dateStr) => {
        const [month, day] = dateStr.split('-').map(Number);
        return `${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    };

    return (
        <div className="hero-section">
            {heroImage ? (
                <div
                    className="hero-image-container"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/images/${heroImage.categoryName}/${heroImage.image_url})`,
                    }}
                >
                    <h1>Bienvenue sur Starz Webradio</h1>
                    <p>Écoutez vos stations préférées en direct</p>
                    <a href="#radios" className="hero-link">Découvrir les stations</a>
                </div>
            ) : (
                <p className="hero-placeholder">Aucune image disponible pour aujourd'hui.</p>
            )}
        </div>
    );
}

export default Hero;