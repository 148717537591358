import React, { useState, useEffect } from 'react';
import './DynamicSongBar.css';

function DynamicSongBar({ stations }) {
    const [currentSongs, setCurrentSongs] = useState([]);
    const [visibleCards, setVisibleCards] = useState(6); // Nombre de cartes visibles
    const [startIndex, setStartIndex] = useState(0); // Index pour la rotation des cartes

    useEffect(() => {
        // Mettre à jour le nombre de cartes visibles en fonction de la largeur de l'écran
        const updateVisibleCards = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth <= 480) {
                setVisibleCards(2);
            } else if (screenWidth <= 768) {
                setVisibleCards(3);
            } else if (screenWidth <= 1024) {
                setVisibleCards(4);
            } else {
                setVisibleCards(6);
            }
        };

        // Attacher un écouteur d'événement pour les changements de taille d'écran
        window.addEventListener('resize', updateVisibleCards);
        updateVisibleCards(); // Appel initial

        return () => window.removeEventListener('resize', updateVisibleCards);
    }, []);

    useEffect(() => {
        const fetchSongs = async () => {
            const updatedSongs = await Promise.all(
                stations.map(async (station) => {
                    try {
                        const sid = station.historyUrl.split("sid=")[1];
                        const url = `https://audio.starzwebradio.com/stats?sid=${sid}`;
                        const response = await fetch(url);
                        const data = await response.text();
                        const parser = new DOMParser();
                        const xmlDoc = parser.parseFromString(data, "text/xml");
                        const songTitle = xmlDoc.getElementsByTagName("SONGTITLE")[0]?.textContent || "Titre non disponible";

                        const [artist, title] = songTitle.split(" - ") || ["Artiste inconnu", "Titre inconnu"];
                        return {
                            name: station.name,
                            logo: station.logo,
                            artist: artist.trim() || "Artiste inconnu",
                            title: title.trim() || "Titre inconnu",
                        };
                    } catch (error) {
                        console.error(`Erreur pour la station ${station.name}:`, error);
                        return {
                            name: station.name,
                            logo: station.logo,
                            artist: "Erreur",
                            title: "Récupération",
                        };
                    }
                })
            );
            setCurrentSongs(updatedSongs);
        };

        fetchSongs();
    }, [stations]);

    useEffect(() => {
        // Logique pour changer les cartes visibles toutes les 10 secondes
        const interval = setInterval(() => {
            setStartIndex((prevIndex) => (prevIndex + visibleCards) % currentSongs.length);
        }, 10000); // 10 secondes

        return () => clearInterval(interval);
    }, [currentSongs, visibleCards]);

    // Calculer les cartes visibles en fonction de l'index de départ et du nombre de cartes visibles
    const displayedCards = currentSongs.slice(
        startIndex,
        startIndex + visibleCards
    ).concat(
        currentSongs.slice(0, Math.max(0, (startIndex + visibleCards) - currentSongs.length))
    );

    return (
        <div>
            <div className="song-bar-header">
                <h2>Sur les autres stations</h2>
            </div>
            <div className="song-bar-container">
                <div className="song-bar">
                    {displayedCards.map((station, index) => (
                        <div key={index} className="song-card">
                            <img
                                src={station.logo}
                                alt={`${station.name} logo`}
                            />
                            <div>
                                <strong>{station.name}</strong>
                                <p>{station.artist}</p>
                                <p>{station.title}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default DynamicSongBar;