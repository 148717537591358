import React, { useEffect, useState } from "react";
import WebRadioPlayer from "./components/WebRadioPlayer";
import "./App.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import CookieConsentBanner from './components/CookieConsentBanner';
import PolitiqueConfidentialite from './components/PolitiqueConfidentialite';
import Hero from './components/Hero';
import Footer from './components/Footer';
import DynamicSongBar from './components/DynamicSongBar';

function App() {
    const [selectedStation, setSelectedStation] = useState(null);
    const [playingStation, setPlayingStation] = useState(null);
    const [hasConsented, setHasConsented] = useState(null);

    // Données des stations en dur
    const radios = [
        { id: 1, name: "Starz 60s", logo: `${process.env.PUBLIC_URL}/logos/60s.png`, stream: "https://audio.starzwebradio.com/stream/13/", historyUrl: "http://62.210.204.2:8000/played.html?sid=13" },
        { id: 2, name: "Starz 70s", logo: `${process.env.PUBLIC_URL}/logos/70s.png`, stream: "https://audio.starzwebradio.com/stream/15/", historyUrl: "http://62.210.204.2:8000/played.html?sid=15" },
        { id: 3, name: "Starz 80s", logo: `${process.env.PUBLIC_URL}/logos/80s.png`, stream: "https://audio.starzwebradio.com/stream/2/", historyUrl: "http://62.210.204.2:8000/played.html?sid=2" },
        { id: 4, name: "Starz 90s", logo: `${process.env.PUBLIC_URL}/logos/90s.png`, stream: "https://audio.starzwebradio.com/stream/9/", historyUrl: "http://62.210.204.2:8000/played.html?sid=9" },
        { id: 5, name: "Starz Blues", logo: `${process.env.PUBLIC_URL}/logos/blues.png`, stream: "https://audio.starzwebradio.com/stream/4/", historyUrl: "http://62.210.204.2:8000/played.html?sid=4" },
        { id: 6, name: "Starz Christmas", logo: `${process.env.PUBLIC_URL}/logos/christmas.png`, stream: "https://audio.starzwebradio.com/stream/12/", historyUrl: "http://62.210.204.2:8000/played.html?sid=12" },
        { id: 7, name: "Starz Classical", logo: `${process.env.PUBLIC_URL}/logos/classical.png`, stream: "https://audio.starzwebradio.com/stream/17/", historyUrl: "http://62.210.204.2:8000/played.html?sid=17" },
        { id: 8, name: "Starz Country", logo: `${process.env.PUBLIC_URL}/logos/country.png`, stream: "https://audio.starzwebradio.com/stream/16/", historyUrl: "http://62.210.204.2:8000/played.html?sid=16" },
        { id: 9, name: "Starz Dance", logo: `${process.env.PUBLIC_URL}/logos/dance.png`, stream: "https://audio.starzwebradio.com/stream/7/", historyUrl: "http://62.210.204.2:8000/played.html?sid=7" },
        { id: 10, name: "Starz Funk", logo: `${process.env.PUBLIC_URL}/logos/funk.png`, stream: "https://audio.starzwebradio.com/stream/11/", historyUrl: "http://62.210.204.2:8000/played.html?sid=11" },
        { id: 11, name: "Starz Italia", logo: `${process.env.PUBLIC_URL}/logos/italia.png`, stream: "https://audio.starzwebradio.com/stream/19/", historyUrl: "http://62.210.204.2:8000/played.html?sid=19" },
        { id: 12, name: "Starz Jazz", logo: `${process.env.PUBLIC_URL}/logos/jazz.png`, stream: "https://audio.starzwebradio.com/stream/5/", historyUrl: "http://62.210.204.2:8000/played.html?sid=5" },
        { id: 13, name: "Starz K-pop", logo: `${process.env.PUBLIC_URL}/logos/kpop.png`, stream: "https://audio.starzwebradio.com/stream/18/", historyUrl: "http://62.210.204.2:8000/played.html?sid=18" },
        { id: 14, name: "Starz Latino", logo: `${process.env.PUBLIC_URL}/logos/latino.png`, stream: "https://audio.starzwebradio.com/stream/20/", historyUrl: "http://62.210.204.2:8000/played.html?sid=20" },
        { id: 15, name: "Starz Lounge", logo: `${process.env.PUBLIC_URL}/logos/lounge.png`, stream: "https://audio.starzwebradio.com/stream/3/", historyUrl: "http://62.210.204.2:8000/played.html?sid=3" },
        { id: 16, name: "Starz Love", logo: `${process.env.PUBLIC_URL}/logos/love.png`, stream: "https://audio.starzwebradio.com/stream/14/", historyUrl: "http://62.210.204.2:8000/played.html?sid=14" },
        { id: 17, name: "Starz New Hits", logo: `${process.env.PUBLIC_URL}/logos/newhits.png`, stream: "https://audio.starzwebradio.com/stream/6/", historyUrl: "http://62.210.204.2:8000/played.html?sid=6" },
        { id: 18, name: "Starz Rap", logo: `${process.env.PUBLIC_URL}/logos/rap.png`, stream: "https://audio.starzwebradio.com/stream/10/", historyUrl: "http://62.210.204.2:8000/played.html?sid=10" },
        { id: 19, name: "Starz RnB", logo: `${process.env.PUBLIC_URL}/logos/rnb.png`, stream: "https://audio.starzwebradio.com/stream/8/", historyUrl: "http://62.210.204.2:8000/played.html?sid=8" },
    ];

    // Fonction pour jouer une station
    const playStation = (station) => {
        console.log(`Lecture de la station : ${station.name}`);
        // Ajoute ici ton code pour démarrer réellement la lecture, par exemple avec un lecteur audio HTML5
    };

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (consent) {
            setHasConsented(JSON.parse(consent));
        }
    }, []);

    const handleConsent = (consentGiven) => {
        setHasConsented(consentGiven);
        localStorage.setItem('cookieConsent', JSON.stringify(consentGiven));
    };

    const handleStationChange = (station) => {
        setSelectedStation(station);
        setPlayingStation(station.name);
    };

    const appStyles = {
        minHeight: '100vh',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    return (
        <Router>
            <div style={appStyles} className="App">
                <>
                    {window.location.pathname === '/' && <Hero />}
                    <Routes>
                        <Route path="/" element={<Home
                            selectedStation={selectedStation}
                            setSelectedStation={setSelectedStation}
                            radios={radios}
                            playingStation={playingStation}
                            setPlayingStation={setPlayingStation}
                            handleStationChange={handleStationChange}
                        />} />
                        <Route path="/politique-confidentialite" element={<PolitiqueConfidentialite />} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                    {hasConsented === null && <CookieConsentBanner onConsent={handleConsent} />}
                </>
            </div>
        </Router>
    );
}

function Home({ selectedStation, setSelectedStation, radios, setPlayingStation }) {
    const mainSectionStyles = {
        width: '100%',
        minHeight: '100vh',
        padding: '0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '20px',
    };

    return (
        <>
            <section style={mainSectionStyles} id="radios">
                <h2 style={{ textAlign: 'center', paddingTop: '20px' }}>Choisissez Votre Station</h2>
                <div className="carousel-container">
                    <div className="radio-carousel">

                        {radios.map((radio) => (
                            <div key={radio.name} className={`radio-item ${selectedStation?.name === radio.name ? 'active' : ''}`} onClick={() => setSelectedStation(radio)}>
                                <img
                                    src={radio.logo}
                                    alt={`${radio.name} logo`}
                                    style={{
                                        width: "150px",
                                        height: "150px",
                                        borderRadius: "15px",
                                        objectFit: "cover",
                                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
                                    }}
                                />
                            </div>
                        ))}

                    </div>
                </div>
                {/* Lecteur WebRadio */}
                <WebRadioPlayer
                    selectedStation={selectedStation}
                    setSelectedStation={setSelectedStation}
                    setPlayingStation={setPlayingStation}
                />
                <section className="dynamic-song-bar-section">
                    <DynamicSongBar stations={radios} />
                </section >
            </section >
            <section className="footer-section">
                <Footer />
            </section>
        </>
    );
}

export default App;