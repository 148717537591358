import React from 'react';
import './Footer.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

// Définition du composant Footer
function Footer() {
    return (
        <div className="footer">
            <div className="footer-content">
                <div className="footer-text">
                    <p>&copy; 2024 Starz Webradio. Tous droits réservés.</p>
                    <SocialLinks />
                </div>
                <img
                    src={`${process.env.PUBLIC_URL}/cookie/cookienoel.png`}
                    alt="Cookie"
                    className="footer-cookie"
                />
            </div>
        </div>
    );
}

// Composant SocialLinks
function SocialLinks() {
    return (
        <div className="social-links">
            <a href="https://www.facebook.com/starzwebradio/" target="_blank" rel="noopener noreferrer">
                <i className="bi bi-facebook"></i>
            </a>
            <a href="https://www.instagram.com/starzwebradioofficiel/" target="_blank" rel="noopener noreferrer">
                <i className="bi bi-instagram"></i>
            </a>
            <a href="https://www.x.com/StarzWebradio" target="_blank" rel="noopener noreferrer">
                <i className="bi bi-twitter-x"></i>
            </a>
        </div>
    );
}

export default Footer;